




















































































import { GanttTask } from "./models";

import { PropType } from "vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class App extends Vue {
  windowWidth = window.innerWidth;
  dialog = false;
  method = 1;
  alert = false;
  task = { name: "", blank: 0, term: 1 };
  idx = 0;

  @Prop({ type: Array as PropType<GanttTask[]>, required: true, default: () => [] }) tasks!: GanttTask[];

  @Watch("task.blank")
  isBlankChanged() {
    if (this.task.blank + this.task.term > 11) this.alert = true;
    else this.alert = false;
  }
  @Watch("task.term")
  isTermChanged() {
    if (this.task.blank + this.task.term > 11) this.alert = true;
    else this.alert = false;
  }
  @Watch("dialog")
  isDialogChanged() {
    if (!this.dialog) {
      this.task = { name: "", blank: 0, term: 1 };
    }
  }

  created() {
    this.fillFavorites();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }

  barColor(idx: number) {
    if ((idx + 1) % 16 == 1) return "red";
    else if ((idx + 1) % 16 == 2) return "pink";
    else if ((idx + 1) % 16 == 3) return "purple";
    else if ((idx + 1) % 16 == 4) return "deep-purple";
    else if ((idx + 1) % 16 == 5) return "indigo";
    else if ((idx + 1) % 16 == 6) return "blue";
    else if ((idx + 1) % 16 == 7) return "light-blue";
    else if ((idx + 1) % 16 == 8) return "cyan";
    else if ((idx + 1) % 16 == 9) return "teal";
    else if ((idx + 1) % 16 == 10) return "green";
    else if ((idx + 1) % 16 == 11) return "light-green";
    else if ((idx + 1) % 16 == 12) return "lime";
    else if ((idx + 1) % 16 == 13) return "yellow";
    else if ((idx + 1) % 16 == 14) return "amber";
    else if ((idx + 1) % 16 == 15) return "orange";
    else return "brown";
  }

  add() {
    this.method = 1;
    this.dialog = true;
  }

  edit(idx: number) {
    this.method = 2;
    this.task = this.tasks[idx];
    this.idx = idx;
    this.dialog = true;
  }

  del(idx: number | null) {
    this.tasks.splice(idx || this.idx, 1);
    this.dialog = false;
  }

  submit() {
    if (this.task.name.trim() && this.task.blank + this.task.term <= 11) {
      this.tasks.push(this.task);
      this.dialog = this.alert = false;
    }
  }

  pushTasks() {
    this.tasks.push(
      { name: "기획", blank: 0, term: 1 },
      { name: "설계", blank: 1, term: 1 },
      { name: "개발", blank: 2, term: 4 },
      { name: "테스트", blank: 6, term: 3 },
      { name: "보고서", blank: 9, term: 2 }
    );
  }

  fillFavorites() {
    this.tasks.push(
      { name: "작업 1", blank: 0, term: 4 },
      { name: "작업 2", blank: 3, term: 5 },
      { name: "작업 3", blank: 5, term: 4 },
      { name: "작업 4", blank: 2, term: 3 },
      { name: "작업 5", blank: 4, term: 2 },
      { name: "작업 6", blank: 5, term: 3 },
      { name: "작업 7", blank: 3, term: 4 },
      { name: "작업 8", blank: 3, term: 2 },
      { name: "작업 9", blank: 2, term: 6 },
      { name: "작업10", blank: 5, term: 3 },
      { name: "작업11", blank: 6, term: 4 },
      { name: "작업12", blank: 8, term: 1 },
      { name: "작업13", blank: 5, term: 3 },
      { name: "작업14", blank: 3, term: 3 },
      { name: "작업15", blank: 4, term: 4 },
      { name: "작업16", blank: 7, term: 3 },
      { name: "작업17", blank: 9, term: 2 }
    );
  }

  replaceTasks() {
    this.tasks.splice(0, this.tasks.length);
    this.tasks.push(
      { name: "기획", blank: 0, term: 1 },
      { name: "설계", blank: 1, term: 1 },
      { name: "개발", blank: 2, term: 4 },
      { name: "테스트", blank: 6, term: 3 },
      { name: "보고서", blank: 9, term: 2 }
    );
  }

  deleteTasks() {
    this.tasks.splice(0, this.tasks.length);
  }
}
